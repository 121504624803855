<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h3>{{ Object.entries(this.data).length === 0 ? "Add New" : "Edit" }} Content</h3>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">
        <h6 class="mb-4">{{ name | capitalize }}</h6>

        <label v-if="this.contentType === 'text'"  class="block mb-2">Content</label>
        <vs-textarea
          v-if="this.contentType === 'text'"
          v-model="content"
          class="w-full"
          name="content"/>
        <span class="text-danger text-sm" v-show="errors.has('content')">{{ errors.first('content') }}</span>

        <vs-input
          v-if="this.contentType === 'button'"
          label="Button Text"
          v-model="buttonText"
          class="mt-5 w-full"
          name="buttonText"
          v-validate="'required'"
          data-vv-as="Button Text"/>
        <span class="text-danger text-sm" v-show="errors.has('buttonText')">{{ errors.first('buttonText') }}</span>

        <vs-input
          v-if="this.contentType === 'button'"
          label="Button Url"
          v-model="buttonUrl"
          class="mt-5 w-full"
          name="buttonUrl"
          v-validate="'required|url'"
          data-vv-as="Button Url"/>
        <span class="text-danger text-sm" v-show="errors.has('buttonUrl')">{{ errors.first('buttonUrl') }}</span>

        <div  v-if="this.contentType === 'image'" class="vx-col w-full mt-5 vs-con-loading__container">
          <template v-if="logo">
            <!-- Image Container -->
            <div class="img-container w-64 mx-auto flex items-center justify-center">
              <img :src="logo" alt="img" class="responsive">
            </div>

            <!-- Image upload Buttons -->
            <div class="modify-img flex justify-between mt-5">
              <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">
              <vs-button class="mr-4"  @click="$refs.updateImgInput.click()">Update Image</vs-button>
              <vs-button class="vs-button-secondary" @click="logo = null">Remove Image</vs-button>
            </div>
          </template>

          <div class="upload-img mt-5" v-if="!logo">
            <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
            <vs-button @click="$refs.uploadImgInput.click()">Upload Image</vs-button>
          </div>
        </div>

        <div  v-if="this.contentType === 'video'" class="vx-col w-full mt-5 vs-con-loading__container">
          <template v-if="video">
            <!-- Image Container -->
              <!--<img :src="video" alt="img" class="responsive">-->

              <video width="320" height="240" controls>
                <source :src="video" type="video/mp4">
                <source :src="video" type="video/ogg">
                Your browser does not support the video tag.
              </video>

            <!-- Image upload Buttons -->
            <div class="modify-img flex justify-between mt-5">
              <input type="file" class="hidden" ref="updateVideoInput" @change="updateCurrVideo" accept="video/*">
              <vs-button class="mr-4"  @click="$refs.updateVideoInput.click()">Update Video</vs-button>
              <vs-button class="vs-button-secondary" @click="video = null">Remove Video</vs-button>
            </div>
          </template>

          <div class="upload-img mt-5" v-if="!video">
            <input type="file" class="hidden" ref="uploadVideoInput" @change="updateCurrVideo" accept="video/*">
            <vs-button @click="$refs.uploadVideoInput.click()">Upload Video</vs-button>
          </div>
        </div>
      </div>


    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center justify-between p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Submit</vs-button>
      <vs-button class="vs-button-secondary" @click="isSidebarActiveLocal = false">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import vSelect from 'vue-select'
  import {mapActions} from "vuex";

  export default {
    data() {
      return {
        dataId: null,
        name: "",
        contentType: "",
        content: "",
        buttonText: "",
        buttonUrl: "",
        logo: null,
        image: null,
        video: null,
        settings: { // perfect scrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
        },
        customError: "",
      }
    },
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        },
      },
    },
    watch: {
      isSidebarActive(val) {
        if (!val) return
        if (Object.entries(this.data).length === 0) {
          this.initValues()
          this.$validator.reset()
        } else {
          this.dataId = this.data._id
          this.name = this.data.name
          this.contentType = this.data.contentType
          if(this.data.contentType === 'text') {
            this.content = this.data.content
          }
          if(this.data.contentType === 'button') {
            this.buttonText = this.data.buttonText
            this.buttonUrl = this.data.buttonUrl
          }
          if(this.data.contentType === 'image') {
            this.image = this.data.content
            this.logo = this.data.content
          }
          if(this.data.contentType === 'video') {
            this.video = this.data.content
          }
         this.initValues()
        }
      },
    },
    computed: {
      isSidebarActiveLocal: {
        get() {
          return this.isSidebarActive
        },
        set(val) {
          if (!val) {
            this.$emit('closeSidebar')
          }
        }
      },
      isFormValid() {
        if(this.data.contentType === 'text') {
        return !this.errors.any()
          && this.name
          && this.content
      }
        if(this.data.contentType === 'button') {
          return !this.errors.any()
            && this.name
            && this.buttonText
            && this.buttonUrl
        }
        if(this.data.contentType === 'image') {
          return !this.errors.any()
            && this.name
            && this.logo
        }
        if(this.data.contentType === 'video') {
          return !this.errors.any()
            && this.name
            //&& this.video
        }
      }
    },
    methods: {
      ...mapActions("setting", [
        "updateContent"
      ]),
      showMessage(title, message, color) {
        this.$vs.notify({
          title: title,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: color
        })
      },
      initValues() {
        if (this.data._id) return
        this.dataId = null
        this.name = ""
        this.contentType = ""
        this.content = ""
        this.buttonText = ""
        this.buttonUrl = ""
        this.logo = null
        this.image = null
        this.video = null
      },
      updateCurrImg(input) {
        if (input.target.files && input.target.files[0]) {
          this.image = input.target.files[0];
          var reader = new FileReader()
          reader.onload = e => {
            this.logo = e.target.result
          }
          reader.readAsDataURL(input.target.files[0])
        }
      },
      updateCurrVideo(input) {
        if (input.target.files && input.target.files[0]) {
          this.video = input.target.files[0];
          console.log(this.video,"this.video");
          var reader = new FileReader()
          reader.onload = e => {
            this.logo = e.target.result
          }
          reader.readAsDataURL(input.target.files[0])
        }
      },
      async submitData() {
        await this.$validator.validateAll().then(result => {
          if (result) {
            let data = new FormData();
            data.append('id', this.dataId);
            data.append('name', this.name);
            data.append('contentType', this.contentType);
            if(this.contentType === 'button') {
              data.append('buttonText', this.buttonText);
              data.append('buttonUrl', this.buttonUrl);
            }
            if(this.contentType === 'image') {
              data.append('content', this.image);
            }
            if(this.contentType === 'video') {
              data.append('content', this.video);
            }
            if(this.contentType === 'text') {
              data.append('content', this.content);
            }
            this.customError = "";
            if (this.dataId !== null) {
              this.$vs.loading()
              this.updateContent(data)
                .then((res) => {
                  if (res.status === 200) {
                    this.showMessage("Success", "Content updated successfully.", "success");
                    this.$emit('refreshData', true);
                  }
                  this.$vs.loading.close()
                })
                .catch(err => {
                  this.$vs.loading.close()
                  //console.error(err)
                })
            } else {
              if (this.logo == null) {
                this.customError = "Image field is required.";
                return;
              }
            }
            this.$emit('closeSidebar')
            this.initValues()
          }
        })
      },
    },
    created(){
    },
    components: {
      vSelect,
      VuePerfectScrollbar,
    }
  }
</script>

<style lang="scss" scoped>
  .add-new-data-sidebar {
    ::v-deep .vs-sidebar--background {
      z-index: 52010;
    }

    ::v-deep .vs-sidebar {
      z-index: 52010;
      width: 400px;
      max-width: 90vw;

      .img-upload {
        margin-top: 2rem;

        .con-img-upload {
          padding: 0;
        }

        .con-input-upload {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
  }
</style>
